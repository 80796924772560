//Used Material UI for everything

import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Box, Paper, FormControlLabel, Switch, TextField, CircularProgress } from "@mui/material";
import "../../styles/line.css";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import buslogo from "../../assets/Bus.svg";
import charging from "../../assets/Charging.svg";
import Routes from "../../assets/Routes.svg";
import tripicon from "../../assets/Trip.svg";
import opretionHouresIcon from "../../assets/opehours.svg";
import TcharginhrIcon from "../../assets/Tcharginhr.svg";
import TkilomitterIcon from "../../assets/Tkilomitter.svg";
import Cookies from 'js-cookie'; // Import js-cookie
import CookieBanner from "../../components/CookieBanner";
import {
  fetchDashboardDetails,
  fetchBusList,
  fetchRouteList,
  fetchChargersList,
} from "../../components/api";
import Testmap from "../../components/Map";
import depot from "../../assets/depot.svg";
import bus from "../../assets/busLegend.svg";
import stop from "../../assets/stop.svg";
import start from "../../assets/start.svg";
import charger from "../../assets/charger.svg";
import route from "../../assets/routeLegend.svg";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios"; // Import axios for making HTTP requests
import LiveBuseslist from "../Buses/LiveBuseslist";
import LiveChargerlist from "../Chargers/LiveChargerlist";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import ImageLoader from "../../assets/Transvolticon 2 (1).gif";
import MapLoader from "../../assets/loader1.gif";
import { LoginContext } from "../../store/LoginContext";
import BlurredBox from "./BlurredBox";

const user = JSON.parse(sessionStorage.getItem("user"));

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: "black",
  backgroundColor: "#323232",
  height: "70vh",
  borderRadius: 0,
  border: "1px solid gray",
  overflowY: "auto",
}));

const MainContainer = styled("div")({
  width: "auto", // Adjusted to full width
  height: "auto", // Changed to auto height for responsiveness
  background: "#626262",
  margin: "0 auto",
  position: "relative",
  paddingBottom: 2,
});

const SmallCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    fontSize: 14, // Adjust the fontSize to make the checkbox smaller
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Dashboard = () => {
  const { logoutHandle } = useContext(LoginContext);
  const [busList, setBusList] = useState([]); // State for bus list
  const [chargersList, setChargersList] = useState([]); // State for bus list
  const [dashboard, setDashboard] = useState(null); // State for dashboard data
  const [selectedOption, setSelectedOption] = useState("Buses"); // State for selected option
  const [routeList, setRouteList] = useState(null); // State for route list
  const [showRoutes, setShowRoutes] = React.useState(false); // State for showing routes
  const [showStops, setShowStops] = React.useState(false); // State for showing stops
  const [selectedRoutes, setSelectedRoutes] = useState([]); // State for selected routes
  const [selectedBuses, setSelectedBuses] = useState([]); // State for selected buses
  const [unselectedBuses, setUnSelectedBuses] = useState([]); // State for selected buses
  const [selectAllBuses, setSelectAllBuses] = useState(false);
  const [selectAllChargers, setSelectAllChargers] = useState(false);
  const [selectedChargers, setSelectedChargers] = useState([]);
  const [allBuses, setAllBuses] = useState([]);
  const [isOn, setIsOn] = useState(false);
  const [VehicleNumber, setVehicleNumber] = useState("");
  const [chargerName, setChargerName] = useState("");
  const [busloading, setBusloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [page, setPage] = useState(1);
  const [totalBusPage, setTotalBusPage] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [pageLoading,setPageLoading] = useState(false)
  const buslistRef = useRef();

  const navigate = useNavigate();

  const hanleOnSelectAllBusesChanges = () => {
    const newSelectAllBuses = !selectAllBuses;
    setSelectAllBuses(newSelectAllBuses);

    if (newSelectAllBuses) {
      // If selecting all buses
      setSelectedBuses(allBuses);
    } else if (busList.length < 20 || VehicleNumber !== "") {
      setPage(1)
      setVehicleNumber(""); // Clear VehicleNumber if conditions are met
      setSelectedBuses([]); // Deselect all buses
    } else {
      setSelectedBuses([]); // Deselect all buses
    }
  };

  useEffect(() => {
    selectedBuses.length > 0 &&
      setSelectAllBuses(busList.length === selectedBuses.length);
  }, [busList.length, selectedBuses.length]);

  const handleOpen = () => {
    navigate("/DeployedBuses");
  };

  const handleRoutesOpen = () => {
    navigate("/Routes");
  };

  const handleChargingOpen = () => {
    navigate("/Charging_Stations");
  };

  const handleShowRouteChange = (event) => {
    setShowRoutes(event.target.checked); // Handle show routes change
  };

  const handleStopsChange = (event) => {
    setShowStops(event.target.checked); // Handle show stops change
  };

  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value); // Handle select option change
  // };

  const handleRoutesChange = (event) => {
    setSelectedRoutes(event.target.value); // Handle routes change
  };

  const handleBusesChange = (event) => {
    setSelectedBuses(event.target.value); // Handle buses change
    // const myArray = busList.filter(function (el) {
    //   return selectedBuses.indexOf(el.BusCode) === -1;
    // });
    const AselectedBuses = selectedBuses;
    AselectedBuses.push(event.target.value);

    const ab = busList.filter((x) => !AselectedBuses.includes(x.BusCode));
    const abc = ab.map((e) => e.BusCode);

    setUnSelectedBuses(abc);
  };

 

  // Fetch dashboard details
  useEffect(() => {
    fetchDashboardDetails()
      .then((data) => {
        setDashboard(data);
      })
      .catch((error) => {
        console.error("Failed to fetch dashboard details:", error);

        if (error.status === 401) {
          logoutHandle();
        }
        // navigate("/");
      });
  }, []);

  const fetchBuslistDetails = async (date, vehicleNumber, page) => {
    
    setBusloading(true);

    try {
      const data = await fetchBusList(date, vehicleNumber);

      if (data?.data?.buses_list && Array.isArray(data.data.buses_list)) {
        setBusList(data.data.buses_list);

        const busCodes = data.data.buses_list.map((e) => e.BusCode);
        setSelectedBuses(busCodes);
        setAllBuses(busCodes);
      } else {
        console.error("Unexpected data structure", data);
      }
    } catch (error) {
      console.error("Failed to fetch bus details:", error);
       if (error.status === 401) {
      logoutHandle();
    }
      // Optionally update UI state to reflect the error
    } finally {
      // Ensure loading state is set to false in all scenarios
      setBusloading(false);
    }
  }
  
  useEffect(() => {
    if (selectedOption === "Buses") {
     let timer=null
      if(page===1){ 
       timer = setTimeout(() => {
        fetchBuslistDetails(
          dayjs(new Date()).format("YYYY-MM-DD"),
          VehicleNumber,
          page
        );
      }, 1000);

    }else{
      fetchBuslistDetails(
        dayjs(new Date()).format("YYYY-MM-DD"),
        VehicleNumber,
        page
      );
    }
      return () => clearTimeout(timer);
    }
  }, [selectedOption, VehicleNumber, page]);

  // Fetch route list
  useEffect(() => {
    if (selectedOption === "Routes") {
      fetchRouteList(dayjs(new Date()).format("YYYY-MM-DD"))
        .then((data) => {
          setRouteList(data.data.buses_list);
        })
        .catch((error) => {
          console.error("Failed to fetch Route details:", error);
          if (error.status === 401) {
            logoutHandle();
          }
        });
    }
  }, [selectedOption]);

  const fetchChargersListData = async (chargerName) => {
    try {
      const data = await fetchChargersList(
        dayjs(new Date()).format("YYYY-MM-DD"),
        "Total",
        chargerName
      );
      setChargersList(data.data.Charger_list);
      setSelectedChargers(data.data.Charger_list);
    } catch (error) {
      console.error("Failed to fetch Chargers details:", error);
      if (error.status === 401) {
        logoutHandle();
      }
    }
  };

  // Fetch Chargers list
  useEffect(() => {
    if (selectedOption === "Chargers") {
      fetchChargersListData(chargerName);

      //   dayjs(new Date()).format("YYYY-MM-DD"),
      //   "Total",
      //   chargerName
      // )
      //   .then((data) => {
      //     setChargersList(data.data.Charger_list);
      //     setSelectedChargers(data.data.Charger_list);
      //   })
      //   .catch((error) => {
      //     console.error("Failed to fetch Chargers details:", error);
      //   });
    }
  }, [selectedOption, chargerName]);

  const hanleOnSelectAllChargersChanges = () => {
    const newSelectAllBuses = !selectAllChargers;
    setSelectAllChargers(!selectAllChargers);

    const data = selectAllChargers !== true ? chargersList : [];
    setSelectedChargers(data);
  };

  useEffect(() => {
    if (chargersList && selectedChargers) {
      setSelectAllChargers(
        chargersList.length > 0 &&
          chargersList.length === selectedChargers.length
      );
    }
  }, [chargersList, selectedChargers]);

  const handleToggle = () => {
    setIsOn((prevState) => !prevState);
  };

  // const handleScroll = () => {
  //   if (!buslistRef.current) return; // Check if the ref is available

  //   const currentScrollY = buslistRef.current.scrollTop; // Get the current scroll position
  //   const clientHeight = buslistRef.current.clientHeight; // Height of the element
  //   const scrollHeight = buslistRef.current.scrollHeight; // Total scrollable height

  //   // Check if the user is scrolling down
  //   if (currentScrollY > prevScrollY) {
  //     if (
  //       currentScrollY + clientHeight >= scrollHeight - 10 &&
  //       !busloading &&
  //       hasMore
  //     ) {
  //       console.log("Reached bottom, loading more data...");
  //       if (totalBusPage >= page) {
  //         setPage((prevPage) => prevPage + 1);
  //         console.log(`Page incremented to: ${page + 1}`); // Log the incremented page
  //       }
  //     }
  //   }
  //   setPrevScrollY(currentScrollY);
  // };

  // useEffect(() => {
  //   const buslistElement = buslistRef.current;

  //   if (buslistElement) {
  //     buslistElement.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (buslistElement) {
  //       buslistElement.removeEventListener("scroll", handleScroll); // Cleanup on unmount
  //     }
  //   };
  // }, [prevScrollY]); // Re-run effect when prevScrollY changes

  // console.log(page, "page");

  return (
    <div className="App">
      <MainContainer>

     
        {/**bus deployed */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // marginY: 1,
            marginX: 1.5,
            gap: 1.5,

            "@media (minWidth: 640px)": {
              flexDirection: "row",
              alignItems: "initial",
            },
          }}
        >
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              width: "50%",
              backgroundColor: "#323232",
              borderRadius: "5px",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              marginTop: "10px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            <CardHeader
              avatar={
                <img
                  src={buslogo}
                  alt="Logo"
                  width={"25px"}
                  height={"25px"}
                  style={{
                    "@media (minWidth: 640px)": {
                      width: "15px",
                      height: "15px",
                    },
                  }}
                />
              }
              style={{
                color: "#fff",
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              titleTypographyProps={{
                style: {
                  fontSize: "0.7rem",
                  flex: 1,
                  "@media (minWidth: 640px)": {
                    fontSize: "0.5rem",
                  },
                },
              }}
              title="Buses Deployed"
            />
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                justifyContent: "center",
                marginTop: 8,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  flex: 1,
                  textAlign: "left",
                  color: "rgba(212,255,212,0.85)",
                  fontWeight: "bold",
                }}
              >
                {dashboard?.data.Bus_count}
              </Typography>
            </CardContent>
          </Card>
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              width: "50%",
              backgroundColor: "#323232",
              borderRadius: "5px",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              marginTop: "10px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            onClick={handleRoutesOpen}
          >
            <CardHeader
              avatar={
                <img
                  src={Routes}
                  alt="Routes"
                  width={"25px"}
                  height={"25px"}
                  style={{
                    "@media (minWidth: 640px)": {
                      width: "15px",
                      height: "15px",
                    },
                  }}
                />
              }
              style={{
                color: "#fff",
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              titleTypographyProps={{
                style: {
                  fontSize: "0.7rem",
                  flex: 1,
                  "@media (minWidth: 640px)": {
                    fontSize: "0.5rem",
                  },
                },
              }}
              title="Routes"
            />
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                justifyContent: "center",
                marginTop: 8,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  flex: 1,
                  textAlign: "left",
                  color: "rgba(212,255,212,0.85)",
                  fontWeight: "bold",
                }}
              >
                {dashboard?.data.route_count}
              </Typography>
            </CardContent>
          </Card>
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              width: "50%",
              backgroundColor: "#323232",
              borderRadius: "5px",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              marginTop: "10px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            onClick={handleChargingOpen}
          >
            <CardHeader
              avatar={
                <img
                  src={charging}
                  alt="Charing"
                  width={"25px"}
                  height={"25px"}
                  style={{
                    "@media (minWidth: 640px)": {
                      width: "15px",
                      height: "15px",
                    },
                  }}
                />
              }
              style={{
                color: "#fff",
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              titleTypographyProps={{
                style: {
                  fontSize: "0.7rem",
                  flex: 1,
                  "@media (minWidth: 640px)": {
                    fontSize: "0.5rem",
                  },
                },
              }}
              title="Charging Stations"
            />
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                justifyContent: "center",
                marginTop: 8,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  flex: 1,
                  textAlign: "left",
                  color: "rgba(212,255,212,0.85)",
                  fontWeight: "bold",
                }}
              >
                {dashboard?.data.Chargers}
              </Typography>
            </CardContent>
          </Card>
        </Box>
        {/**trips */}

        <Box
          name={"dailyChange"}
          display={"flex"}
          gap={1.5}
          marginX={1.5}
          marginBottom={1}
        >
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#007FEC",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "30%",
              height: 90,
              borderRadius: 10,
              "@media (maxWidth: 768px)": {
                width: "100%",
                height: 50,
              },
            }}
          >
            <CardHeader
              title="Trips"
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "0.9rem", fontWeight: "bold" },
              }}
              style={{
                color: "#fff",
                textAlign: "left",
                padding: "10px 16px 0 16px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                padding: "0  15px 0px",
              }}
            >
              <img
                src={tripicon}
                alt="Logo"
                style={{ alignSelf: "center", width: "20px", height: "20px" }}
              />

              <Typography
                variant="h4"
                style={{ color: "rgba(0, 0, 0, 0.6)", textAlign: "right" }}
              >
                <span
                  style={{
                    fontSize: 25,
                    color: " rgba(0, 0, 0, 0.5",
                    fontWeight: "bold",
                  }}
                >
                  {dashboard?.data.TotalTrips}
                </span>
              </Typography>
            </div>
          </Card>

          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FF5551",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "30%",
              height: 90,
              borderRadius: 10,
            }}
          >
            <CardHeader
              title="Operation Hours"
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "0.9rem", fontWeight: "bold" },
              }}
              style={{
                color: "#fff",
                textAlign: "left",
                padding: "10px 16px 0 16px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                // padding: "20px  15px 50px",
                paddingLeft: "15px",
              }}
            >
              <img
                src={opretionHouresIcon}
                alt="Logo"
                style={{ alignSelf: "flex-end", width: "35px", height: "35px" }}
              />

              <Typography
                variant="h4"
                style={{
                  textAlign: "right",
                  color: " rgba(0, 0, 0, 0.5",
                  fontWeight: "bold",
                }}
              >
                <span style={{ fontSize: 25 }}>
                  {dashboard?.data.OperationalHour}
                </span>
                <span style={{ fontSize: 10 }}>Hrs</span>
              </Typography>
            </div>
          </Card>

          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#F89A14",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "30%",
              height: 90,
              borderRadius: 10,
            }}
          >
            <CardHeader
              title="Total Kilometer"
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "0.9rem", fontWeight: "bold" },
              }}
              style={{
                color: "#fff",
                textAlign: "left",
                padding: "10px 16px 0 16px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                paddingLeft: "15px",
              }}
            >
              <img
                src={TkilomitterIcon}
                alt="Logo"
                style={{ alignSelf: "flex-end", width: "35px", height: "35px" }}
              />
              <Typography
                variant="h4"
                style={{
                  textAlign: "right",
                  color: " rgba(0, 0, 0, 0.5",
                  fontWeight: "bold",
                }}
              >
                <span style={{ fontSize: 25 }}>
                  {dashboard?.data.DistanceBytrip_in_Km}
                </span>
                <span style={{ fontSize: 10 }}>Km</span>
              </Typography>
            </div>
          </Card>

          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#00B448",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "30%",
              height: 90,
              borderRadius: 10,
            }}
          >
            <CardHeader
              title="Total Charging Hours"
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "0.9rem", fontWeight: "bold" },
              }}
              style={{
                color: "#fff",
                textAlign: "left",
                padding: "10px 16px 0 16px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                // padding: "20px 15px 0px",
                paddingLeft: "15px",
              }}
            >
              <img
                src={TcharginhrIcon}
                alt="Logo"
                style={{ alignSelf: "flex-end", width: "35px", height: "35px" }}
              />
              <Typography
                variant="h4"
                style={{
                  textAlign: "right",
                  color: " rgba(0, 0, 0, 0.5",
                  fontWeight: "bold",
                }}
              >
                <span style={{ fontSize: 25 }}>
                  {dashboard?.data.charging_hours}
                </span>
                <span style={{ fontSize: 10 }}>Hrs</span>
              </Typography>
            </div>
          </Card>
        </Box>
      </MainContainer>

      {/* Main content area with map and sidebar */}
      <Box
        sx={{
          background: "#606060",
          height: "82vh",
          // overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          // overflow: "hidden",
          // minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            background: "#000000",
            height: 55,
            marginX: 1.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              color: "#fff",
              // width: "412px",
              background: "#262626",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "16.41px",
              textAlign: "left",
              height: "100%",
              // width:420
              // borderBottom: "2px solid #fff", // Optional: Adds a border at the bottom for a more defined look
            }}
          >
            <Box
              sx={{
                flex: 1, // Distributes space equally
                borderRight: "0.5px solid #434343",
                padding: "8px", // Optional: Adds some padding inside each Box
                background: selectedOption === "Buses" ? "#2A426C" : "",
                width: "112px",
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16.41px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedOption("Buses");
              }}
            >
              Buses
            </Box>
            {/* <Box
              sx={{
                flex: 1, // Distributes space equally
                borderRight: "0.5px solid #fff",
                padding: "8px", // Optional: Adds some padding inside each Box
                background: selectedOption === "Routes" ? "Blue" : "",
              }}
              onClick={() => {
                setSelectedOption("Routes");
              }}
            >
              Routes
            </Box> */}
            <Box
              sx={{
                flex: 1, // Distributes space equally
                padding: "8px", // Optional: Adds some padding inside each Box
                borderRight: "0.5px solid #434343",
                background: selectedOption === "Chargers" ? "#2A426C" : "",
                width: "112px",
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16.41px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedOption("Chargers");
              }}
            >
              Chargers
            </Box>
            {isLoading && (
              <div
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  background: "#000000",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                  // justifySelf: "center",
                  display: "flex",
                  // padding: "10px",
                }}
              >
                <p
                  style={{
                    marginLeft: "20px",
                    color: "#54a9d9",
                  }}
                >
                  Loading Buses
                </p>
                <img
                  src={MapLoader}
                  alt="loader"
                  style={{
                    // width:"30px",
                    height: "15px",
                  }}
                />
              </div>
            )}
          </Box>

          <Box sx={{ display: "flex", color: "#fff" }}>
            <Typography
              sx={{
                fontSize: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 1,
                gap: 1.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>Legends:</Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <p>Depot</p>
                <img
                  src={depot}
                  alt="depot"
                  style={{ marginLeft: 3, width: "1.4rem" }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <p>Bus</p>
                <img
                  src={bus}
                  alt="bus"
                  style={{ marginLeft: 3, width: "1.2rem" }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p> Charger </p>
                  <svg
                    width="21"
                    height="25"
                    viewBox="0 0 21 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.5"
                      cy="12.5"
                      r="7.25"
                      fill="#38D700"
                      stroke="black"
                      strokeWidth="0.5"
                    />
                    <g filter="url(#filter0_d_2056_13077)">
                      <path
                        d="M8.78433 17.5892C8.41503 17.5892 8.17852 17.1962 8.35152 16.8699L9.99391 13.7723L8.02107 13.5242C7.1873 13.4194 6.8468 12.3953 7.4518 11.8121L11.7426 7.67592C11.9191 7.50576 12.1547 7.41068 12.3999 7.41068C12.6855 7.41068 12.8684 7.71469 12.7346 7.96702L11.0058 11.2276L12.9787 11.4757C13.8124 11.5805 14.1529 12.6046 13.5479 13.1878L9.12432 17.4521C9.03302 17.5401 8.91114 17.5892 8.78433 17.5892Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_2056_13077"
                        x="0.644531"
                        y="0.910675"
                        width="19.7104"
                        height="23.1786"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="3.25" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_2056_13077"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_2056_13077"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <p>Stop</p>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5.5"
                    cy="5.5"
                    r="5"
                    fill="black"
                    stroke="#828282"
                  />
                </svg>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <p>Start/End</p>
                <img
                  src={start}
                  alt="Start-End"
                  style={{ marginLeft: 3, width: "0.6rem" }}
                />
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", marginRight: 2 }}
              >
                <p> Route</p>
                <img
                  src={route}
                  alt="Route"
                  style={{ marginLeft: 3, width: "1.4rem" }}
                />
              </Box>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginX: 1.4,
            minHeight: "100%",
            // height:"100vh" ,
            // height:"100%"
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              sx={{
                flex: "0 0 auto",
                overflowY: "auto",
                background: "#323232",
                borderBottom: "none",
                // height: `calc(100vh - 100px)`,
                height:"70vh",

              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  border: "none",
                  height: "100%",
                }}
              >
                {selectedOption === "Buses" && (
                  <Item
                    sx={{
                      maxWidth: "fit-content",
                      border: "none",
                      outline: "none",
                      height: "100%",
                    }}
                    ref={buslistRef}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          border: "1px solid #656565",
                          width: 150,
                          marginLeft: "10px",
                          marginTop: "10px",
                          height: "25px",
                          borderRadius: "30px",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          placeholder="Search"
                          size="small"
                          value={VehicleNumber}
                          onChange={(e) => setVehicleNumber(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                                boxShadow: "none",
                              },
                              background: "transparent",
                              borderRadius: "22.5px 0 0 22.5px",
                              height: "100%",
                              padding: "0 0 0 10px",
                              marginRight: "5px",
                              color: "#fff",
                              width: `calc("100%" - "50px")`,
                              fontSize: "10px",
                            },
                            "& .MuiInputBase-input": {
                              padding: "0",
                            },
                          }}
                        />
                        <svg
                          width="35"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            margin: "5px 0px 0px 0px",
                          }}
                          role="img"
                          aria-label="Icon description" // Add a descriptive label
                        >
                          <path
                            d="M13.77 13.1659L11.1264 10.5301C11.9794 9.44347 12.4422 8.10162 12.4404 6.72022C12.4404 5.48998 12.0756 4.28736 11.3921 3.26445C10.7087 2.24154 9.73719 1.44428 8.60059 0.973489C7.464 0.502695 6.21332 0.379514 5.00672 0.619523C3.80011 0.859531 2.69178 1.45195 1.82186 2.32186C0.951949 3.19178 0.359531 4.30011 0.119523 5.50672C-0.120486 6.71332 0.0026951 7.964 0.473489 9.10059C0.944282 10.2372 1.74154 11.2087 2.76445 11.8921C3.78736 12.5756 4.98998 12.9404 6.22022 12.9404C7.60162 12.9422 8.94347 12.4794 10.0301 11.6264L12.6659 14.27C12.7382 14.3429 12.8242 14.4007 12.9189 14.4402C13.0137 14.4797 13.1153 14.5 13.218 14.5C13.3206 14.5 13.4222 14.4797 13.517 14.4402C13.6117 14.4007 13.6977 14.3429 13.77 14.27C13.8429 14.1977 13.9007 14.1117 13.9402 14.017C13.9797 13.9222 14 13.8206 14 13.718C14 13.6153 13.9797 13.5137 13.9402 13.4189C13.9007 13.3242 13.8429 13.2382 13.77 13.1659ZM1.55506 6.72022C1.55506 5.79754 1.82866 4.89558 2.34128 4.12839C2.85389 3.36121 3.58249 2.76327 4.43494 2.41017C5.28739 2.05708 6.2254 1.96469 7.13035 2.1447C8.0353 2.3247 8.86655 2.76902 9.51899 3.42145C10.1714 4.07389 10.6157 4.90514 10.7957 5.81009C10.9757 6.71504 10.8834 7.65305 10.5303 8.5055C10.1772 9.35795 9.57923 10.0865 8.81205 10.5992C8.04486 11.1118 7.1429 11.3854 6.22022 11.3854C4.98294 11.3854 3.79634 10.8939 2.92145 10.019C2.04656 9.1441 1.55506 7.9575 1.55506 6.72022Z"
                            fill="#656565"
                          />
                        </svg>
                      </Box>
                      <div style={{ margin: "12px 26px 0px 18px" }}>
                        <input
                          type="checkbox"
                          checked={selectAllBuses}
                          onChange={hanleOnSelectAllBusesChanges}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                        <label
                          style={{
                            color: "#fff",
                            marginLeft: "10px",
                          }}
                        >
                          All
                        </label>
                      </div>
                    </Box>

                    {busloading !== true ? (
                      busList?.length > 0 &&
                      busList?.map((busId, index) => (
                        <Box
                          sx={{
                            paddingX: 1,
                            paddingY: 0,
                          }}
                          key={index}
                        >
                          <LiveBuseslist
                            busId={busId}
                            selectedBuses={selectedBuses}
                            setSelectedBuses={setSelectedBuses}
                            VehicleNumber={VehicleNumber}
                            setVehicleNumber={setVehicleNumber}
                            setPage={setPage}

                          />
                        </Box>
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "150px",
                          marginLeft: "80px",
                        }}
                      >
                        <img src={ImageLoader} alt="loader" />
                      </div>
                    )}
                    {pageLoading && <CircularProgress />}
                  </Item>
                )}

                {selectedOption === "Routes" && (
                  <Item>
                    {routeList?.map((route) => (
                      <Box
                        sx={{
                          paddingX: 2,
                          paddingY: 0.5,
                        }}
                        key={route.route_id}
                      >
                        <Grid
                          container
                          sx={{
                            border: "1px solid #555555",
                            borderRadius: "10px",
                            background: "#252525",
                          }}
                        >
                          <Grid
                            item
                            xs={1}
                            sx={{
                              borderRight: "1px solid #555555",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{ color: "#ffffff", fontSize: "0.5rem" }}
                            >
                              {route.Code}
                            </Typography>
                          </Grid>
                          <Grid item xs={11}>
                            <Box
                              sx={{
                                padding: 1,
                                borderBottom: "1px solid #555555",
                              }}
                            >
                              <Typography
                                sx={{ color: "#ffffff", fontSize: "0.5rem" }}
                              >
                                <p> Route Number / Code </p>:
                                <p> {route.Code}</p>
                              </Typography>
                            </Box>
                            <Box sx={{ padding: 1 }}>
                              <Typography
                                sx={{ color: "#ffffff", fontSize: "0.5rem" }}
                              >
                                {route.Name}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Item>
                )}
                {selectedOption === "Chargers" && (
                  <Item
                    sx={{
                      maxWidth: "fit-content",
                      border: "none",
                      outline: "none",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        // border: "1px solid #656565",
                        // width: 200,
                        marginLeft: "10px",
                        // marginTop: "20px",
                        // height: "30px",
                        // borderRadius: "30px",
                        justifyContent: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          border: "1px solid #656565",
                          width: 150,
                          marginLeft: "0px",
                          // marginTop: "20px",
                          height: "25px",
                          borderRadius: "30px",
                          marginTop: "10px",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          placeholder="Search"
                          size="small"
                          value={chargerName}
                          onChange={(e) => setChargerName(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                                boxShadow: "none",
                              },
                              background: "transparent",
                              borderRadius: "22.5px 0 0 22.5px",
                              height: "100%",
                              padding: "0 0 0 10px",
                              marginRight: "5px",
                              color: "#fff",
                              width: "150px",
                              fontSize: "10px",
                            },
                            "& .MuiInputBase-input": {
                              padding: "0",
                            },
                          }}
                        />
                        <svg
                          width="20"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            margin: "5px 10px 0px 0px",
                          }}
                          role="img"
                          aria-label="Search icon" // Add a descriptive label
                        >
                          <path
                            d="M13.77 13.1659L11.1264 10.5301C11.9794 9.44347 12.4422 8.10162 12.4404 6.72022C12.4404 5.48998 12.0756 4.28736 11.3921 3.26445C10.7087 2.24154 9.73719 1.44428 8.60059 0.973489C7.464 0.502695 6.21332 0.379514 5.00672 0.619523C3.80011 0.859531 2.69178 1.45195 1.82186 2.32186C0.951949 3.19178 0.359531 4.30011 0.119523 5.50672C-0.120486 6.71332 0.0026951 7.964 0.473489 9.10059C0.944282 10.2372 1.74154 11.2087 2.76445 11.8921C3.78736 12.5756 4.98998 12.9404 6.22022 12.9404C7.60162 12.9422 8.94347 12.4794 10.0301 11.6264L12.6659 14.27C12.7382 14.3429 12.8242 14.4007 12.9189 14.4402C13.0137 14.4797 13.1153 14.5 13.218 14.5C13.3206 14.5 13.4222 14.4797 13.517 14.4402C13.6117 14.4007 13.6977 14.3429 13.77 14.27C13.8429 14.1977 13.9007 14.1117 13.9402 14.017C13.9797 13.9222 14 13.8206 14 13.718C14 13.6153 13.9797 13.5137 13.9402 13.4189C13.9007 13.3242 13.8429 13.2382 13.77 13.1659ZM1.55506 6.72022C1.55506 5.79754 1.82866 4.89558 2.34128 4.12839C2.85389 3.36121 3.58249 2.76327 4.43494 2.41017C5.28739 2.05708 6.2254 1.96469 7.13035 2.1447C8.0353 2.3247 8.86655 2.76902 9.51899 3.42145C10.1714 4.07389 10.6157 4.90514 10.7957 5.81009C10.9757 6.71504 10.8834 7.65305 10.5303 8.5055C10.1772 9.35795 9.57923 10.0865 8.81205 10.5992C8.04486 11.1118 7.1429 11.3854 6.22022 11.3854C4.98294 11.3854 3.79634 10.8939 2.92145 10.019C2.04656 9.1441 1.55506 7.9575 1.55506 6.72022Z"
                            fill="#656565"
                          />
                        </svg>
                      </Box>
                      <div style={{ margin: "12px 26px 0px 18px" }}>
                        <input
                          type="checkbox"
                          checked={selectAllChargers}
                          onChange={hanleOnSelectAllChargersChanges}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                        <label
                          style={{
                            color: "#fff",
                            marginLeft: "10px",
                          }}
                        >
                          All
                        </label>
                      </div>
                    </Box>

                    {chargersList?.map((busId, index) => (
                      <Box
                        sx={{
                          paddingX: 1,
                          paddingY: 0,
                        }}
                        key={index}
                      >
                        <LiveChargerlist
                          busId={busId}
                          selectedChargers={selectedChargers}
                          setSelectedChargers={setSelectedChargers}
                        />
                      </Box>
                    ))}
                  </Item>
                )}
              </Box>
            </Grid>

            <Grid
              item
              sx={{
                flex: "1 1 auto",
                // height: `calc(100vh - 100px)`,
                height:"70vh",
                overflowY: "auto",
                zIndex: 0,
              }}
            >
              {/* Map Component */}
              <Testmap
                selectedBuses={selectedBuses}
                // unselectedBuses={unselectedBuses}
                vehicleNumber={VehicleNumber}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <CookieBanner /> */}

     
    </div>
  );
};

export default Dashboard;
