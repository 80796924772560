import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/style.css";
import App from "./App";
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';
// import '@nextui-org/react/style.css';

import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoginContextProvider from "./store/LoginContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <LoginContextProvider>
      <App />
      <ToastContainer />
    </LoginContextProvider>
  </BrowserRouter>

  //  </React.StrictMode>
);
