import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/system";
import logo from "../assets/Tranvolt_Logo_SVG 1.svg";
import mbmtlogo from "../assets/MBMT - Logo - PNG - Small 1.png";

import profile from "../assets/profile.svg";
import { Box } from "@mui/material";
import { LoginContext } from "../store/LoginContext";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "black",
  height: "45px",
  // width: "100%",
  margin: "0 0 -2px 0px",
  paddingX: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexGrow: "grow",
  flexWrap: "wrap",
});

const Header = ({ username }) => {
  const { logoutHandle } = useContext(LoginContext);
  return (
    <StyledAppBar position="static">
      <Box  alignSelf={"center"} marginLeft={2}>
        <img src={logo} alt="Logo" width={"70%"} />
      </Box>
      <Box
        alignSelf={"center"}
        fontSize={"15px"}
        fontFamily={`"Roboto", "Helvetica", "Arial", sans-serif`}
        sx={{
          display: "flex",
          justifyContent: "center",
          // alignContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            height: "40px",
            display: "flex",
            justifyContent: "center",
            // alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            // marginTop:"-12px",
            marginRight:"7px",
            marginLeft:"-120px"
            
          }}
        >
          <img src={mbmtlogo} alt="logo" style={{ height: "80%" }} />
        </Box>
        <Box
          sx={{
            height: "40px",
            // marginTop
          }}
        >
          <p style={{ paddingTop: "8px", margin: "0px" }}>
          Mira Bhainder Municipal Corporation (Transport Division)
          </p>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingX={"10px"}
        alignSelf={"center"}
        gap={1}
      >
        {/* <Box alignSelf={"center"} fontSize={"10px"}>
          info@transvolt.com
        </Box> */}
        {/* <Box alignSelf={"center"}>
          <img src={profile} alt="Profile" width={"60%"} />
        </Box> */}
        <Box
        sx={{
          cursor:"pointer"
        }}
          onClick={() => {
            logoutHandle();
          }}
        >
          <svg
            width="25"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20.5" cy="20.5" r="20.5" fill="#333333" />
            <path
              d="M15.1911 13.6458C13.5132 14.7594 12.2384 16.3836 11.5555 18.278C10.8726 20.1725 10.8179 22.2365 11.3994 24.1645C11.9809 26.0925 13.1678 27.782 14.7844 28.9829C16.4009 30.1838 18.3612 30.8323 20.375 30.8323C22.3888 30.8323 24.3491 30.1838 25.9656 28.9829C27.5822 27.782 28.7691 26.0925 29.3506 24.1645C29.9321 22.2365 29.8774 20.1725 29.1945 18.278C28.5116 16.3836 27.2368 14.7594 25.5589 13.6458M20.3755 10V18.3333"
              stroke="#9D9D9D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </Box>
    </StyledAppBar>
  );
};

export default Header;
